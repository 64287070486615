export var textFromTextBlock = function(block) {
    return block.children.map(function(c) {
        return c.text;
    }).join(" ");
};
export var anchorIdFromTextBlock = function(block) {
    return encodeURIComponent(anchorIdFromString(block.children.map(function(c) {
        return c.text;
    }).join()));
};
export var anchorIdFromString = function(text) {
    return encodeURIComponent(text.toLowerCase().replace(/[^\w\-\s]/g, "").replace(/\s+/g, "-"));
};
