import { z } from "zod";
// SELECT (string_to_array(email, '@')) [2], count(*) FROM people.email_address GROUP BY 1 ORDER BY 2 DESC;
// We could also use something like https://github.com/ZooTools/email-spell-checker
var INVALID_DOMAINS = [
    "b.c",
    "example.com",
    "gamil.com",
    "gmai.com",
    "gmail.c",
    "gmail.co",
    "gmail.cok",
    "gmail.coma",
    "gmail.comc",
    "gmail.con",
    "gmail.cpm",
    "gnail.com",
    "hotmail.c",
    "hotmail.co",
    "hotmail.con",
    "hotnail.com",
    "msn.con",
    "outlook.om",
    "yandex.tu", 
];
export var emailSchema = z.string().min(5).email().refine(function(val) {
    return !val || !INVALID_DOMAINS.some(function(domain) {
        return val.endsWith("@".concat(domain));
    });
}, "Invalid email domain");
