import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { z } from "zod";
// Client side we need public sanity constants
var sanityPublicEnvSchema = z.object({
    NEXT_PUBLIC_SANITY_DATASET: z.string().optional(),
    NEXT_PUBLIC_SANITY_PROJECT_ID: z.string()
});
var BASE_CONFIG = {
    apiVersion: "2022-02-28",
    useCdn: [
        "production",
        "test"
    ].includes("production"),
    dataset: "production"
};
export var publicConfig = function() {
    var sanityEnv = sanityPublicEnvSchema.parse({
        // Write out process.env.* as it will be injected upon build by Nextjs based on pattern matching
        NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
        NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET
    });
    return _object_spread_props(_object_spread({}, BASE_CONFIG), {
        dataset: sanityEnv.NEXT_PUBLIC_SANITY_DATASET || BASE_CONFIG.dataset,
        projectId: sanityEnv.NEXT_PUBLIC_SANITY_PROJECT_ID
    });
};
