import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { orderBy } from "lodash";
// [0, 1, 2, 3, 4] -> [4, 2, 0, 1, 3]
export var orderFromCenter = function(arr, orderByKey) {
    if (orderByKey) arr = orderBy(arr, orderByKey);
    // Even indexes in descending order followed by odd indexes in ascending order
    var indexes = orderBy(arr.map(function(_, i) {
        return i;
    }), function(i) {
        return (i % 2 == 0 ? -1 : +1) * i;
    });
    return indexes.map(function(i) {
        return arr[i];
    });
};
var dateStrings = new Map();
export var getMonthYearDateString = function(date) {
    // Memoize date rendering, as it's very expensive
    var yearMonth = date.slice(0, 7) // YYYY-MM
    ;
    if (dateStrings.has(yearMonth)) return dateStrings.get(yearMonth);
    var dateString = new Date(date).toLocaleDateString("en-UK", {
        year: "numeric",
        month: "long",
        timeZone: "UTC"
    });
    dateStrings.set(yearMonth, dateString);
    return dateString;
};
export var asQueryString = function(params) {
    return Object.entries(params).filter(function(param) {
        var _param = _sliced_to_array(param, 2), v = _param[1];
        return v !== null && v !== undefined;
    }).map(function(param) {
        var _param = _sliced_to_array(param, 2), k = _param[0], v = _param[1];
        return "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(v));
    }).join("&");
};
